/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import { GetStaticPaths, GetStaticProps } from 'next';
import Head from 'next/head';
import Script from 'next/script';
import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';

import { BlogCard } from '@components/Blog/BlogCard';
import { AddToFavourites } from '@components/Button';
import { BulkCarousel } from '@components/Carousels/Carousel';
import { FPTProductsCarousel } from '@components/Carousels/FPTProductsCarousel';
import { Container } from '@components/Container';
import { ProductNav } from '@components/Header/ProductNav/ProductNav';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { CustomImage, getImageSrcUrl } from '@components/Image/Image';
import { ProductLayout } from '@components/Layouts';
import { PortableTextBlockRenderer } from '@components/PortableText/PortableText';
import {
  AverageRating,
  ImageGallerySection,
  LabResultList,
} from '@components/Product';
import { ProductInformation } from '@components/Product/PageSections/ProductInformation';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useStockLevel } from '@hooks/useStockLevel';
import { MetaData } from '@interfaces/MetaData';
import {
  BaseProduct,
  Product,
  ProductDescription,
  S3LabResult,
} from '@interfaces/Product';
import { CloudinaryAsset, FAQ, SanityImage } from '@interfaces/Sanity';
import {
  addBigCommerceDataToBaseProducts,
  getAllProductSlugs,
  getBreadcrumbs,
  getFPTProducts,
  getLabResultsByProduct,
  getMetaData,
  getProductData,
  getProductInStockSS,
  getSingleProductById,
} from '@lib/data/productData';
import { isVariantAvailable } from '@lib/productHelpers';
import { getProductAlertById } from '@lib/queries/sanity/alerts';
import { getRedirect } from '@lib/redirects';
import { cdnClient as client } from '@lib/sanityClient';
import { convertFAQToStructedData } from '@lib/structuredData';

function ProductTitle({ product }: { product: Product }) {
  return (
    <div>
      <h2 className="flex items-center pb-2 pt-5 text-2xl font-bold italic leading-none tracking-tighter text-black dark:text-white sm:text-left">
        <AddToFavourites sku={product.sku} productId={product.entityId} />
        {product.name}
      </h2>

      {product.tgaStatement ? (
        <p className="mt-0">{product.tgaStatement}</p>
      ) : null}

      <AverageRating
        averageRating={product.averageRating}
        reviews={product.totalReviews}
      />
    </div>
  );
}

const RendererOverride = {
  block: {
    h2: ({ children }: { children?: ReactNode }) => (
      <h2 className="mt-10 pb-2 font-bold italic text-orange [&+.hr]:hidden">
        {children}
      </h2>
    ),
    h3: ({ children }: { children?: ReactNode }) => (
      <>
        <HorizontalDivider className="via-grey-mid" />
        <h3 className="font-bold">{children}</h3>
      </>
    ),
  },
};

const fetcher = async ([url, productId]) => {
  const params = {
    id: productId,
  };

  const result = await client.fetch(getProductAlertById, params);

  if (result) {
    return result;
  } else {
    const error = new Error('Unable to fetch product alert');
    throw error;
  }
};

export interface Props {
  product: Product;
  breadcrumbs: { title: string; path: string }[];
  meta: MetaData;
  labResults: S3LabResult[];
  fptProducts: BaseProduct[];
  faqStructuredData: unknown;
  isPreview: boolean;
  onPageNav: { title: string; id: string }[];
}

// eslint-disable-next-line no-restricted-syntax
export default function ProductPage({
  product,
  breadcrumbs, // used in layout
  meta,
  labResults,
  fptProducts,
  faqStructuredData,
  isPreview, // used in layout
  onPageNav,
}: Props): ReactElement {
  // Hooks
  const { data: alert, error } = useSWR(
    [`/api/productAlerts`, product.entityId],
    fetcher
  );
  const { inStock, stockLevels } = useStockLevel(product.entityId.toString());
  const { customer } = useCustomer();
  const rhfMethods = useForm<any>({
    mode: 'onTouched',
  });

  // Local State
  const [variantImages, setVariantImages] = useState(
    product.variants.reduce((acc, cur) => {
      const available = isVariantAvailable(
        cur,
        customer ? customer.customerGroupId : undefined
      );
      if (available && cur.images.length > 0) {
        acc.push(...cur.images);
      }
      return acc;
    }, [] as SanityImage[])
  );
  const [images, setImages] = useState([...product.images, ...variantImages]);

  //#region Effects
  // Pull images out of variants
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setVariantImages(
        product.variants.reduce((acc, cur) => {
          const available = isVariantAvailable(
            cur,
            customer ? customer.customerGroupId : undefined
          );
          if (available && cur.images.length > 0) {
            acc.push(...cur.images);
          }
          return acc;
        }, [] as SanityImage[])
      );
    }
    return function cleanup() {
      mounted = false;
    };
  }, [customer, product.variants, product]);

  // When product changes, reset things
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setImages([...product.images, ...variantImages]);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [product, variantImages]);

  // Set the default variant as selected
  useEffect(() => {
    const defaultVariant = product.variants.filter(
      (v) => v.variantId === product.defaultVariantId
    )[0];

    // Check if variant is in stock
    if (
      stockLevels &&
      defaultVariant &&
      stockLevels[defaultVariant.variantId]
    ) {
      // If in stock, set it as selected
      defaultVariant.options.forEach((option) => {
        rhfMethods.setValue(
          option.optionId.toString(),
          option.valueId.toString()
        );
      });
    } else if (stockLevels) {
      // If default not in stock, find the first in stock variant and set that as selected
      const firstInStock = product.variants.find(
        (v) =>
          stockLevels[v.variantId] && v.variantId !== product.defaultVariantId
      );
      if (firstInStock) {
        firstInStock.options.forEach((option) => {
          rhfMethods.setValue(
            option.optionId.toString(),
            option.valueId.toString()
          );
        });
      }
    }
  }, [stockLevels, product.defaultVariantId, product.variants, product]);
  //#endregion

  const getPortableTextBlocks = (block: ProductDescription) => (
    <PortableTextBlockRenderer
      content={
        block.contentBody?.map((b) =>
          Object.assign(b, {
            productId: product.entityId,
          })
        ) || []
      }
      componentsOverride={RendererOverride}
    />
  );

  const getArticleBlock = (block: ProductDescription) => {
    if (block.relatedBlogs && block.relatedBlogs.length > 0) {
      return (
        <div
          className="full-bleed mt-10 !bg-grey-dark"
          id={block.contentDescription.split(' ').join('-')}
        >
          {block.contentDescription.toLowerCase() === 'recipes' ? (
            <h2 className="mt-10 pb-2 text-center font-bold italic text-white">
              {product.name} Recipes
            </h2>
          ) : (
            <h2 className="mt-10 pb-2 text-center font-bold italic text-white">
              Further Reading on {product.name}
            </h2>
          )}
          <BulkCarousel className="mb-10" fadeTo="to-grey-dark">
            {block.relatedBlogs.map((blog, idx) => (
              <BlogCard blog={blog} key={`featuredBlog${idx}`} />
            ))}
          </BulkCarousel>
        </div>
      );
    }

    // if block is lab test results
    if (block.contentDescription === 'Lab Results') {
      return (
        <div
          id={block.contentDescription.split(' ').join('-')}
          key={block.contentDescription.split(' ').join('-')}
        >
          {getPortableTextBlocks(block)}
          {labResults.length ? <LabResultList items={labResults} /> : null}
        </div>
      );
    }

    if (block.contentDescription === 'Nutrition Panels') {
      return (
        <div id={block.contentDescription.split(' ').join('-')}>
          <div className="max-w-screen grid grid-cols-1 gap-4 md:grid-cols-2 [&>*:not(table)]:col-span-full">
            {getPortableTextBlocks(block)}
          </div>
        </div>
      );
    }

    return (
      <div id={block.contentDescription.split(' ').join('-')}>
        {getPortableTextBlocks(block)}
      </div>
    );
  };

  const divider = (block, nextBlock): ReactElement => {
    if (block.relatedBlogs && block.relatedBlogs.length > 0) {
      return <></>;
    }
    if (
      nextBlock &&
      nextBlock.relatedBlogs &&
      nextBlock.relatedBlogs.length > 0
    ) {
      return <></>;
    }
    return (
      <div>
        <HorizontalDivider className="mb-0" />
      </div>
    );
  };

  const threeMonthsDate = new Date();
  threeMonthsDate.setMonth(threeMonthsDate.getMonth() + 3);

  const getStructuredDataImageUrl = (image: SanityImage | CloudinaryAsset) => {
    try {
      return getImageSrcUrl({ image: image, width: 500 });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Head>
        <>
          <title>{meta.metaTitle}</title>
          {faqStructuredData && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(faqStructuredData),
              }}
            />
          )}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: product.name,
                aggregateRating: {
                  '@type': 'AggregateRating',
                  ratingValue: product.averageRating,
                  reviewCount: product.totalReviews,
                },
                image: [
                  getStructuredDataImageUrl(
                    product.images.filter((i) => i.defaultImage)[0] ??
                      product.images[0]
                  ),
                ],
                description: product.shortDescription,
                offers: {
                  '@type': 'Offer',
                  availability: product.serverSideStock
                    ? 'https://schema.org/InStock'
                    : 'https://schema.org/OutOfStock',
                  url: `${process.env.NEXT_PUBLIC_SERVER_URL}${product.slug}`,
                  priceCurrency: 'AUD',
                  price: product.pricing.price.value,
                  priceValidUntil: `${threeMonthsDate.getFullYear()}-${`0${
                    threeMonthsDate.getMonth() + 1
                  }`.slice(-2)}-${('0' + threeMonthsDate.getDate()).slice(-2)}`,
                },
              }),
            }}
          />
          <meta name="title" content={meta.metaTitle} />
          <meta name="description" content={meta.metaDescription} />
          {meta.noIndex && <meta name="robots" content="noindex" />}
          <link
            rel="canonical"
            href={
              meta.canonical
                ? meta.canonical
                : `${process.env.NEXT_PUBLIC_SERVER_URL}${product.slug}`
            }
          />
        </>
      </Head>
      <Script
        id="klaviyo-tracker"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
              var _learnq = _learnq || [];
              var item = {
                "ProductName": "${product.name}",
                "ProductID": "${product.entityId}",
                "SKU": "${product.sku}",
                "ImageURL": "${getStructuredDataImageUrl(
                  product.images.filter((i) => i.defaultImage)[0] ??
                    product.images[0]
                )}",
                "URL": "https://bulknutrients.com.au${product.slug}",
                "Price": "${product.pricing.price.value}",
              };
              _learnq.push(["track", "Viewed Product", item]);

              _learnq.push(["trackViewedItem", {
                "Title": item.ProductName,
                "ItemId": item.ProductID,
                "ImageUrl": item.ImageURL,
                "Url": item.URL,
                "Metadata": {
                  "Price": item.Price,
                }
              }]);
              `,
        }}
      />
      <Container
        as="main"
        id="main-content"
        className="mb-[60px] bg-transparent bg-white dark:bg-black"
      >
        <section
          id="product-head-details"
          className="grid grid-cols-1 gap-5 lg:grid-cols-[auto_400px]"
          data-testid="product-head"
          data-product-id={product.entityId}
        >
          <div className="lg:hidden">
            <ProductTitle product={product} />
          </div>
          <ImageGallerySection images={images} />

          <div className="">
            <div className="hidden lg:block">
              <ProductTitle product={product} />
            </div>

            <FormProvider {...rhfMethods}>
              <ProductInformation
                product={product}
                inStock={inStock}
                stockLevels={stockLevels}
                rhfMethods={rhfMethods}
              />
            </FormProvider>
          </div>
        </section>

        {(alert || (product.cta && product.cta.length > 0)) && (
          <div
            className="flex flex-col flex-nowrap items-center gap-6 py-5 
            data-[theme=kidActiv]:bg-white data-[theme=plant]:bg-white 
            data-[theme=kidActiv]:dark:bg-black data-[theme=plant]:dark:bg-black
            xl:flex-row xl:flex-wrap xl:items-start"
            data-theme={product.theme ? product.theme[0] : ''}
          >
            {!error && alert && Object.keys(alert).length > 0 && (
              <div className="product-alert-cta mx-auto my-2.5 border border-solid border-orange bg-white p-1 dark:bg-grey-darkest sm:p-2.5">
                <PortableTextBlockRenderer content={alert.content} />
              </div>
            )}
            {product.cta &&
              product.cta.map((cta) => (
                <div className="mx-auto mt-5" key={cta._key}>
                  <CustomImage image={cta} width={1200} />
                </div>
              ))}
          </div>
        )}

        <FPTProductsCarousel
          relatedProducts={fptProducts}
          theme={product.theme ? product.theme[0] : ''}
        />

        <ProductNav links={onPageNav} />

        {product.description &&
          product.description.map((block, i) => (
            <Fragment key={block._key}>
              {getArticleBlock(block)}
              {divider(block, product.description[i + 1])}
            </Fragment>
          ))}

        <div id="Reviews">
          <h2 className="pb-2 pt-10 font-bold italic text-orange">
            {product.name} Reviews
          </h2>
          <div
            className="yotpo yotpo-main-widget"
            data-product-id={product.entityId}
            data-price={
              product.pricing.basePrice
                ? product.pricing.basePrice.value
                : product.pricing.price.value
            }
            data-currency="AUD"
            data-name={product.name}
            data-url={`https://bulknutrients.com.au${product.slug}`}
            data-image-url={product.defaultImgUrl}
            data-description={product.shortDescription}
          />
        </div>
      </Container>
    </>
  );
}

ProductPage.getLayout = function getLayout(
  page: ReactElement & { props: Props }
) {
  return <ProductLayout {...page.props}>{page}</ProductLayout>;
};

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = await getAllProductSlugs();
  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({
  params,
  preview,
  previewData,
}) => {
  const product = preview
    ? await getSingleProductById((previewData as { id: string }).id)
    : await getProductData(params?.product?.toString() as string);

  if (!product) {
    // try redirect
    const redirect = await getRedirect(
      ('/products/' + params?.product?.toString()) as string
    );
    if (redirect) {
      return {
        redirect: {
          destination: redirect.newUrl,
          permanent: redirect.permanent,
        },
      };
    }

    // fallback failed
    return {
      notFound: true,
    };
  }

  // Attach the server side fetched stock boolean
  product.serverSideStock = await getProductInStockSS(product.entityId);

  const breadcrumbs = await getBreadcrumbs(product.entityId);
  const meta = await getMetaData(product.entityId);
  const labResults = await getLabResultsByProduct(product);
  const fptProducts = await getFPTProducts(product.entityId);
  let faqs: { question: string; answer: string }[];

  if (product.description) {
    faqs = (
      product.description
        .map((d) => d.contentBody)
        .flat()
        .filter((c) => c && c._type === 'faq') as FAQ[]
    ).map((faq) => ({
      question: faq.question,
      answer: faq.answer
        .map((a) => a.children.map((c) => c.text).join(' '))
        .join(' '),
    }));
  } else faqs = [];

  const faqStructuredData = convertFAQToStructedData(faqs);

  // If there is a product carousel, add the BC data that is needed for a BaseProduct
  // Get all products out
  const products = [] as Array<any>;
  product.description.forEach((description) => {
    if (description.contentBody) {
      description.contentBody.forEach((content) => {
        if (
          content._type === 'carousel' &&
          content.items[0]._type === 'product'
        ) {
          content.items.forEach((item) => {
            products.push(item);
          });
        }
      });
    }
  });

  // Map products
  const mappedProducts = await addBigCommerceDataToBaseProducts(products);

  // Replace them
  product.description = product.description.map((description) => {
    if (description.contentBody) {
      description.contentBody = description.contentBody.map((content) => {
        if (
          content._type === 'carousel' &&
          content.items[0]._type === 'product'
        ) {
          const mappedItems = content.items.map((item) =>
            mappedProducts.find((p) => p.entityId === item.entityId)
          );
          return {
            ...content,
            items: mappedItems,
          };
        }
        return content;
      });
    }
    return description;
  });

  // Links for the page nav
  const links = product.description
    ? product.description.map((block) => ({
        title: block.contentDescription,
        id: `${block.contentDescription.split(' ').join('-')}`,
      }))
    : [];

  links.push({ title: 'Reviews', id: 'Reviews' });
  links.push({ title: 'Top', id: 'top' });

  return {
    props: {
      product,
      breadcrumbs,
      meta,
      labResults,
      fptProducts,
      faqStructuredData,
      isPreview: false,
      onPageNav: links,
    },
  };
};
